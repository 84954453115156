import * as THREE from 'three'

var _glsl_vertex_glsl= require("./glsl/vertex.glsl")
var _glsl_fragment_glsl= require("./glsl/fragment.glsl")

export default class Waves extends THREE.Object3D {
    constructor(color){
        super();
        this.color = "#ededed";
        this.clock = new THREE.Clock();
        this.geometry = new THREE.PlaneGeometry(4,1,64,16);
        this.material = new THREE.ShaderMaterial({
            vertexShader: _glsl_vertex_glsl,
            fragmentShader: _glsl_fragment_glsl,
            uniforms: {
                time: {value: 1.0},
                color: new THREE.Uniform(color),
                noiseSpeed: {value: 0.5},
                noiseAmp: {value: 0.10},
                noiseFreq: {value: 1.5},
                gaussianWaveAmp: {value: 0.12},
                gaussianWavePos: {value: 2.5},
                gaussianWaveVar: {value: 0.15},
                gaussianRipple: {value: false},
            },
            wireframe: true,
        });
        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.add(this.mesh);
        this.rotation.x = 0.5*Math.PI
    }

    render() {
        this.material.uniforms.time.value = this.clock.getElapsedTime();
        this.material.uniforms.color.value.set(this.color);
    }

    changeColor(color) {
        this.color = color;
    }

    changeNoiseAmp(amp) {
        this.material.uniforms.noiseAmp.value = amp;
    }

    changeNoiseFreq(freq) {
        this.material.uniforms.noiseFreq.value = freq;
    }

    changeSpeed(speed) {
        this.material.uniforms.noiseSpeed.value = speed;
    }
}


