import * as THREE from 'three'

import MorphSphere from './MorphSphere';

import * as Scene from '../scenes/scene.js';

import * as Cursor from '../cursor.js';

import { gsap } from "gsap/dist/gsap";


const sceneContainer = $('#nav-button-scene');
const navMenuContainer = $("#nav-menu-container")
const height = sceneContainer.height();
const width = sceneContainer.width();


const renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
renderer.setPixelRatio(window.devicePixelRatio);

const camera = new THREE.PerspectiveCamera(45, width / height, .5, 1000);
camera.position.z = 3;

export const morphSphere = new MorphSphere(new THREE.Color("#ededed"));

const morphSpreadDefault = 0.1;
const morphSpeedDefault = 2.0;
const rotationSpeedDefault = 0.01;

const sphereProperties = {
    morphAmp: 0.0,
    morphSpeed: morphSpeedDefault,
    morphSpread: morphSpreadDefault,
    rotationSpeed: rotationSpeedDefault,
    sphereScale: 1.0,
    sphereColor: "#ededed",
};

const scene = setupScene();


export function init() {

    renderer.setSize(height, width);

    window.addEventListener('resize', onWindowResize);

    sceneContainer.on("mouseenter", (e) => {
        gsap.to(sphereProperties, {
            morphAmp: 0.20,
            rotationSpeed: rotationSpeedDefault * 3,
            sphereScale: 1.075,
            duration: 0.2,
            ease: "power3.in",
        });
    });

    sceneContainer.on("mouseleave", (e) => {
        gsap.to(sphereProperties, {
            morphAmp: 0.0,
            rotationSpeed: rotationSpeedDefault,
            sphereScale: 1.0,
            duration: 0.2,
            ease: "power3.out",
        });
    });

    sceneContainer.on("mouseleave", (e) => {
    });

    if (!navMenuContainer.attr("data-nav-menu")) {
        navMenuContainer.attr("data-nav-menu", "closed");
        navMenuContainer.addClass('hidden');
    }else {
        // Check state and color according to state
        if (navMenuContainer.attr("data-nav-menu") == "open") {
            sphereProperties.sphereColor = "#101010";
            Scene.changeWavesColor("#101010");
            Cursor.changeCursorColor("#101010");
        }else {
            sphereProperties.sphereColor = "#ededed";
        }
    }
    

    sceneContainer.append(renderer.domElement);
    animate();
}

function onWindowResize() {
    let h = sceneContainer.height(); 
    let w = sceneContainer.width();
    camera.aspect = w / h
    camera.updateProjectionMatrix();
    renderer.setSize(w,h);
}

function animate() {
    requestAnimationFrame(animate);
    // rotate sphere
    updateSphere();
    morphSphere.render();
    renderer.render(scene, camera);
}

function updateSphere() {
    morphSphere.rotateY(sphereProperties.rotationSpeed);
    morphSphere.mesh.scale.set(sphereProperties.sphereScale,sphereProperties.sphereScale,sphereProperties.sphereScale);

    let uniforms = morphSphere.material.uniforms;
    uniforms.morphAmp.value = sphereProperties.morphAmp;
    uniforms.morphSpeed.value = sphereProperties.morphSpeed;
    uniforms.morphSpread.value = sphereProperties.morphSpread;
    uniforms.color.value.set(sphereProperties.sphereColor);

}

function setupScene() {
    const scene = new THREE.Scene();
    scene.add(morphSphere);

    return scene;
}

export function toggleNav() {
    // check nav state
    let state = navMenuContainer.attr("data-nav-menu");
    
    if (state == "closed") {
        // Open Menu
        gsap.to(sphereProperties, {
            sphereColor: "#101010",
        })
        Scene.changeWavesColor("#101010");
        Cursor.changeCursorColor("#101010");


        navMenuContainer.attr("data-nav-menu", "open");
    }else {
        // Close Menu
        gsap.to(sphereProperties, {
            sphereColor: "#ededed",
        })
        Scene.changeWavesColor("#ededed");
        Cursor.changeCursorColor("#ededed");

        navMenuContainer.attr("data-nav-menu", "closed");
    }

}
