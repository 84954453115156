import { gsap } from "gsap/dist/gsap";

const tween = gsap.fromTo("#experience-section .marquee-item > img", 
    {
        opacity: 0.0,
    }, 
    {
        opacity: 1.0,
        duration: 0.4,
        ease: "power2.in",
        stagger: {
            each: 0.05,
            from: "center",
            ease: "sine.out",
        },
    });

tween.restart();
tween.pause();


const workTextTween = gsap.from("#work-list li", {
    opacity: 0.0,
    transform: "translateX(.75rem)",
    ease: "power2.in",
    stagger: {
        each: 0.15,
    }
});
workTextTween.restart();
workTextTween.pause();

const educationTextTween = gsap.from("#education-list li", {
    opacity: 0.0,
    transform: "translateX(.75rem)",
    ease: "power2.in",
    stagger: {
        each: 0.15,
    }
});

educationTextTween.restart();
educationTextTween.pause();

$(document).ready(() => {

    $("#experience-section").on("becomesactive",() => {
        // Fade in the various images
        tween.play();
        educationTextTween.play();
        workTextTween.play();
    });
});