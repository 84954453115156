import { gsap } from "gsap/dist/gsap";
    
import { TextPlugin } from "gsap/dist/TextPlugin";

import { navManager } from "./index.js";


gsap.registerPlugin(TextPlugin);


const interests = [
    // My dev career
    {interest: "Embedded Systems", link: "#thesis-section-container"},
    // My teaching career
    {interest: "Development", link: "#portfolio-section-container"},
    // My education
    {interest: "Technology", link: "#experience-section"},
    // My personal life
    {interest: "Cyber Security", link: "#thesis-section-container"},
    // Surfing
    {interest: "Surfing", link: "#surfing-section-container"},
    // Surfing
];

const interestsTimeLine = createChangingInterestsTimeline();
interestsTimeLine.play();

function createChangingInterestsTimeline() {

    const items = gsap.utils.toArray('a.interest-text');
    let tl = gsap.timeline();

    interests.forEach(({interest, link}) => {
        tl.to('.interest-text', 
            {
                text: interest,
                duration: 1,
                onStart: () => {
                    let elem = $('.interest-text');
                    elem.off("click");
                    elem.on("click", () => {
                        navManager.navigateTo(link);
                    });
                },
            }
        );
        tl.to('.interest-text',
            {
                text: "",
                duration: 0.5,
                onStart: () => {
                    items.forEach((elem) => elem.href = "");
                }
            },
            ">5" 
            );
    });
    tl.delay(1);
    tl.repeat(-1);
    tl.pause();
    return tl
}


const scrollSectionElem = $("#scroll-section"); // Container of introsection

export function fadeIn(delay = 0.5) {

    // Remove current active element

    let tl = gsap.timeline();
    // Fade in effect
    tl.fromTo(scrollSectionElem, {opacity: 0.0}, {
        opacity: 1.0,
        duration: 1,
        ease: "power3.in",
    });

    scrollSectionElem.addClass('active').trigger('becomesactive');
    scrollSectionElem.removeClass('hidden');
}

scrollSectionElem.on("becomesactive", () => {
    interestsTimeLine.play();
});




//$("#scroll-section .back-button").each((_, button) => {
//    gsap.fromTo(button, {opacity: 0.0}, {
//        opacity: 1.0,
//        duration: .25,
//        ease: "power2.in",
//        delay: 1.5,
//        scrollTrigger: {
//            onEnter: () => button.classList.remove('hidden'),
//            onLeaveBack: () => {
//                gsap.to(button, {
//                    opacity: 0.0,
//                    onComplete: () => button.classList.add('hidden'),
//                })
//            },
//            toggleActions: "restart none none none",
//            horizontal: true,
//            once: false,
//            start: "right+=1 center",
//            end: "right left-=1",
//        }
//    });
//
//});

$("#technology-section").each((_, element) => {

    gsap.from(element, {
        transform: "translateY(0.5rem)",
        opacity: 0.0,
        duration: .5,
        delay: .2,
        ease: "power2.in",
        scrollTrigger: {
            onLeaveBack: () => {
                gsap.to(element, {opacity: 0.0});
            },
            toggleActions: "restart none none none",
            horizontal: true,
            once: false,
            start: "right+=1 center",
            end: "right left-=1",
        }
    });

});