import { gsap } from "gsap/dist/gsap";
    
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { TextPlugin } from "gsap/dist/TextPlugin";


gsap.registerPlugin(ScrollTrigger,ScrollToPlugin,TextPlugin);

import * as Scene from './scenes/scene.js';

export default class NavigationManager {

    constructor() {
        this.backButton = $("#back-button");

        this.backButton.on("click", () => {
            this.fadeOutBackButton();
            this.navigateTo("#introduction-section-container");
        });
    }

    onResize() {
    }

    fadeOutBackButton() {
        gsap.to(this.backButton, {
            opacity: 0.0,
            onComplete: () => this.backButton.addClass('hidden'),
        });
    }

    fadeInBackButton() {
        gsap.to(this.backButton, {
            opacity: 1.0,
            onStart: () => this.backButton.removeClass('hidden'),
        });
    }

    setBackButtonRight() {
        this.backButton.children().addClass('right');
    }

    setBackButtonLeft() {
        this.backButton.children().removeClass('right');
    }

    handleBackButton(target) {
        // Checks for backbutton attribute
        let dir = target.attr('data-nav-button');

        if (dir === undefined) {
            this.fadeOutBackButton();
        }else if (dir == "right") {
            this.setBackButtonRight();
            this.fadeInBackButton();
        }else if (dir == "left") {
            this.setBackButtonLeft();
            this.fadeInBackButton();
        }


    }

    scrollTargetOffset(scrollTarget) {
        let elem = scrollTarget[0];

        // Determine elements index
        let parentChildren = Array.prototype.slice.call(elem.parentElement.children);
        let index = parentChildren.indexOf(elem);
        return index * 100
    }


    scrollTo(scrollTarget) {

        let scrollOffset = this.scrollTargetOffset(scrollTarget);

        // Remove scroll-focus status from current scroll-focus
        $(".scroll-focus").removeClass('scroll-focus');
        // Retrieve dom object
        let elem = scrollTarget[0];
        // Determine scroll direction
        let isBackward = elem.getBoundingClientRect().x < 0 ? true : false;

        // Trigger wave animation and store whether it will play
        let waveAnim = Scene.triggerWave(isBackward);
        // Only delay scroll if animation will play
        let delay = waveAnim ? .66 : 0;

        // Scroll to correct section
        gsap.to(".scroll-container", {
            transform: `translateX(${-scrollOffset}%)`,
            delay: delay,
            onComplete: () => {
                scrollTarget.addClass('scroll-focus').trigger('becomesactive');
                this.handleBackButton(scrollTarget);
            },
        });

    }


    fadeTransition(target, onCompleteCallback = undefined) {


        Scene.triggerRipple();
        // Remove active from current element
        let activeElem = $(".active")

        if (activeElem[0].id == target[0].id) {
            // We are already at the target
            return;
        }

        activeElem.removeClass('active');

        let delay = .66;

        let tl = gsap.timeline();
        tl.to(activeElem, {
            opacity: 0.0,
            ease: "power2.out",
            duration: .75,
            onComplete: () => {
                activeElem.addClass('hidden');
            },
        });

        if (onCompleteCallback == undefined) {
            onCompleteCallback = () => {this.handleBackButton(target)};
        }

        tl.fromTo(target, {opacity: 0.0}, {
            opacity: 1.0,
            ease: "power2.in",
            delay: delay,
            duration: .5,
            onStart: () => {
                target.removeClass('hidden');
                target.addClass('active').trigger('becomesactive');
            },
            onComplete: onCompleteCallback,
        }, "<");

    }

    navigateTo(sectionId) {
        // Get target element
        let target = $(sectionId);
        let activeElem = $('.active');

        let scrollContainer = $('#scroll-section');

        if (!target.length) {
            console.error(`Could not find target element ${sectionId}.`);
            return;
        }

        if (!activeElem.length) {
            console.error(`Could not find active element.`);
            // Fix by going to introduction section
            scrollContainer.addClass('active');
            this.navigateTo("#introduction-section-container");
            return;
        }

        let targetInScroll = ($(target).parents('.scroll-container').length) ? true : false;
        this.fadeOutBackButton();

        // Target outside of scroll section
        if (!targetInScroll) {
            this.fadeTransition(target);
            return;
        }

        // Scroll section active and target inside of scroll section
        if (scrollContainer.hasClass('active') && targetInScroll) {
            this.scrollTo(target);
            return;
        }

        // Scroll section inactive and target inside of scroll section
        if (!scrollContainer.hasClass('active') && targetInScroll) {
            let offset = this.scrollTargetOffset(target);
            $(".scroll-focus").removeClass('scroll-focus');
            target.addClass('scroll-focus').trigger('becomesactive');
            // Move immediately in scroll focus
            scrollContainer.css("transform", `translateX(${-offset}%)`);

            // We have a custom onComplete callback
            // because, the navigation target is different than the fade target
            let onCompleteCallback  = () => {
                this.handleBackButton(target);
            };

            // We want to fade in scrollContainer
            this.fadeTransition(scrollContainer, onCompleteCallback);
            return;
        }

        // Fade out and Fade in appropriate section
        this.fadeTransition(target);
    }

    handleScreenChange() {
        // We must recenter the scroll section if active
        let scrollContainer = $("#scroll-section");
        if (scrollContainer.hasClass('active')) {
            // Find scroll focus and recenter there
            let scrollFocus = $('.scroll-focus');
            if (!scrollFocus.length) {
                console.error("Could not find scroll-focus");
                // Recover by setting introduction section as scroll-focus
                $("#introduction-section").addClass('scroll-focus');
                scrollFocus = $("#introduction-section");
            }

            let offset = this.scrollTargetOffset(scrollFocus);
            scrollContainer.css("transform", `translateX(${-offset}%)`);
        }

    } 
    

} 