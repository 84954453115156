import * as THREE from 'three'

var _glsl_vertex_glsl= require("./glsl/spheremorph_vec.glsl")
var _glsl_fragment_glsl= require("./glsl/fragment.glsl")

export default class MorphSphere extends THREE.Object3D {
    constructor(color){
        super();
        this.clock = new THREE.Clock();
        this.geometry = new THREE.SphereGeometry(1,7,5);
        this.material = new THREE.ShaderMaterial({
            vertexShader: _glsl_vertex_glsl,
            fragmentShader: _glsl_fragment_glsl,
            uniforms: {
                time: {value: 1.0},
                color: new THREE.Uniform(color),
                morphAmp: {value: 0.2},
                morphSpread: {value: 0.5},
                morphSpeed: {value: 1.5},
                sphereRadius: {value: 1.0},
            },
            wireframe: true,
        });
        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.add(this.mesh);
    }

    render() {
        this.material.uniforms.time.value = this.clock.getElapsedTime();
    }

    changeColor(color) {
        this.material.uniforms.color = new THREE.Uniform(color);
    }

    changeNoiseAmp(amp) {
        this.material.uniforms.noiseAmp.value = amp;
    }

    changeNoiseFreq(freq) {
        this.material.uniforms.noiseFreq.value = freq;
    }

    changeSpeed(speed) {
        this.material.uniforms.noiseSpeed.value = speed;
    }
}


