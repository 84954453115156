import { gsap } from "gsap/dist/gsap";
import $ from 'jquery';


window.$ = $;
window.jQuery = jQuery;

const leftButton = $("#portfolio-control-left")
const rightButton = $("#portfolio-control-right")
const sectionElem = $("#portfolio-section-container");
const paginationElem = $("#portfolio-pagination");

const PORTFOLIO_ITEM_COUNT = $("#portfolio-imgs").children().length;


$(document).ready(() => {
    paginationInit();
    leftButton.on("click", previousPortfolioItem);
    rightButton.on("click", nextPortfolioItem);

    // Allow portfolio controls through mobile gestures
    var hammer = new Hammer($(".portfolio-content-wrapper")[0]);
    hammer.on('swipeleft', previousPortfolioItem)
    hammer.on('swiperight', nextPortfolioItem)
})

function paginationInit() {
    // insert bullets for every portfolio item
    paginationElem.append(`<div class="portfolio-pagination-bullet on" data-portfolio-item="0"></div>`);
    for (let i = 1; i < PORTFOLIO_ITEM_COUNT; i++) {
        paginationElem.append(`<div class="portfolio-pagination-bullet" data-portfolio-item="${i}"></div>`);
    }

    let bulletClickHandler = (e) => {{
        let bullet = e.target;
        let index = parseInt(bullet.getAttribute("data-portfolio-item"));
        if (index != NaN) {
            changePortfolioItem(index);
        }
    }}

    $(".portfolio-pagination-bullet").on("click", bulletClickHandler);



}

function nextPortfolioItem() {
    let index = parseInt(sectionElem.attr("data-portfolio-item"));
    let newIndex = (index + 1) % PORTFOLIO_ITEM_COUNT;
    changePortfolioItem(newIndex);
}

function previousPortfolioItem() {
    let index = parseInt(sectionElem.attr("data-portfolio-item"));
    let newIndex = ((index - 1) % PORTFOLIO_ITEM_COUNT + PORTFOLIO_ITEM_COUNT) % PORTFOLIO_ITEM_COUNT;
    changePortfolioItem(newIndex);
}

function imageTransition(newIndex) {
    let activeImg = $(".portfolio-img-source.active-portfolio-item"); 
    activeImg.removeClass('active-portfolio-item');

    let newImg = $(`.portfolio-img-source[data-portfolio-item-index="${newIndex}"]`);
    newImg.addClass('active-portfolio-item');

    // Fade out old image and Fade in new image
    gsap.to(activeImg, {
        opacity: 0.0,
        duration: 2.0,
    });
    gsap.to(newImg, {
        opacity: 1.0,
        duration: 2.0,
    });
}

var animating = false;

function titleTransition(newIndex) {
    // Slide out old title
    let oldTitle = $(".portfolio-title.active-portfolio-item");
    let newTitle = $(`.portfolio-title[data-portfolio-item-index=${newIndex}]`);

    let tl = gsap.timeline();
    tl.to(oldTitle, {
        transform: "translateY(200%)",
        opacity: 0.0,
        ease: "power2.out",
        onComplete: () => {
            oldTitle.removeClass('active-portfolio-item');
            oldTitle.addClass('hidden');
            newTitle.addClass('active-portfolio-item');
            newTitle.removeClass('hidden');
        }
    }, "+=0.25");

    tl.fromTo(newTitle, {opacity: 0.0, transform: "translateY(200%)"}, {
        transform: "translateY(0%)",
        opacity: 1.0,
        ease: "power2.in",
        onStart: () => {
            newTitle.addClass('active-portfolio-item');
        },
        onComplete: () => {
            animating = false;
        }
    });
}

function descriptionTransition(newIndex) {
    // Slide out old title
    let oldDesc = $(".portfolio-description.active-portfolio-item");
    let newDesc = $(`.portfolio-description[data-portfolio-item-index=${newIndex}]`);

    let tl = gsap.timeline();
    tl.to(oldDesc, {
        transform: "translateX(-2em)",
        opacity: 0.0,
        ease: "power2.out",
        onComplete: () => {
            oldDesc.removeClass('active-portfolio-item');
            oldDesc.addClass('hidden');
            newDesc.addClass('active-portfolio-item');
            newDesc.removeClass('hidden');
        }
    }, "+=0.25");

    tl.fromTo(newDesc, {opacity: 0.0, transform: "translateX(-2em)"}, {
        transform: "translateX(0%)",
        opacity: 1.0,
        ease: "power2.in",
        onStart: () => {
            newDesc.addClass('active-portfolio-item');
        },
        onComplete: () => {
            animating = false;
        }
    });
}

function paginationUpdate(newIndex) {
    // Remove on class
    $(".portfolio-pagination-bullet").removeClass('on');
    $(".portfolio-pagination-bullet").eq(newIndex).addClass('on');

}

export function changePortfolioItem(newIndex) {
    if (!animating) {
        animating = true;
        let index = parseInt(sectionElem.attr("data-portfolio-item"));
        if (index == newIndex) return; // No change neccesary

        imageTransition(newIndex);
        titleTransition(newIndex);
        descriptionTransition(newIndex);
        paginationUpdate(newIndex);
        sectionElem.attr("data-portfolio-item", newIndex);
    };

}