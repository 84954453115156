import { gsap } from "gsap/dist/gsap";
    
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { TextPlugin } from "gsap/dist/TextPlugin";


gsap.registerPlugin(ScrollTrigger,ScrollToPlugin,TextPlugin);

const mouseElem = $(".mouse-pointer")[0];

function moveCursor(e) {
    const mouseX =  e.clientX;
    const mouseY =  e.clientY;

    mouseElem.style.left = mouseX + "px";
    mouseElem.style.top = mouseY + "px";
}

function pulseCursor(e) {
    let tl = gsap.timeline();
    tl.from(mouseElem, 
        {
            scale: 0.8,
            ease: "elastic",
            duration: .75,
        
        }
        );
}

/* Cursor animation */
const rotateTween = gsap.to(mouseElem, {
    rotation: 360,
    ease: "linear",
    duration: 4,
    repeat: -1,
});

export function changeCursorColor(color) {
    $('.mouse-pointer .semi-circle').css("border", `1px solid ${color}`);
}


$(document).ready(function() {
    document.addEventListener("mousemove", moveCursor);
    document.addEventListener("mousedown", pulseCursor);

    // Add speed up to clickable links
    $("a").on("mouseenter", () => {
        rotateTween.timeScale(4);
        gsap.to(mouseElem, {
            scale: 1.4,
        })
    });
    $("a").on("mouseleave", () => {
        rotateTween.timeScale(1);
        gsap.to(mouseElem, {
            scale: 1.0,
            ease: "power2.out"
        })
    });
});

